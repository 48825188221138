import styled from 'styled-components';

import media from '../../../../common/MediaQueries';
import { Tabs } from '../../Tabs/styles';

export const HeroTabs = styled.div`
  margin: 2rem auto 0;
  width: 100%;
  max-width: 24.625rem;
  display: flex;
  align-items: center;

  ${Tabs} {
    background-color: ${({ theme }) => theme.colors.additional.accentGray3};
  }

  @media ${media.phone} {
    width: 50%;
    margin: 0 1rem 0 3rem;
  }

  @media ${media.tablet} {
    width: 100%;
    margin-left: 6rem;
    margin-right: 1.75rem;
  }
`;
