import React from 'react';

import * as Styled from './styles';

import Tabs from '../../Tabs';
import { HeroWithTabsProps } from './types';
import HeroBase from '..';

const HeroWithTabs: React.FC<HeroWithTabsProps> = ({ data }) => {
  const { tabs, ...restHeroProps } = data;

  return (
    <HeroBase data={{ ...restHeroProps }} hasTabs>
      {tabs.length && (
        <Styled.HeroTabs>
          <Tabs items={tabs} isAlt />
        </Styled.HeroTabs>
      )}
    </HeroBase>
  );
};

export default HeroWithTabs;
