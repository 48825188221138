import { graphql } from 'gatsby';
import React from 'react';

import { getHref, getImage } from '../../../../utils/sanityTypes';
import { MediaItemProps } from '../../../atoms/Image/types';
import HeroWithTabs from '../../../organisms/Hero/HeroWithTabs';
import { TabsItemProps } from '../../../organisms/Tabs/types';

import { HeroWithTabsSectionProps } from './types';

const HeroWithTabsSection: React.VFC<HeroWithTabsSectionProps> = ({
  title,
  subtitle,
  scrollButtonLabel,
  content: contentRichText,
  images,
  tabs,
}) => (
  <HeroWithTabs
    data={{
      title: title ?? '',
      subtitle: subtitle ?? '',
      scrollButtonLabel: scrollButtonLabel ?? '',
      contentRichText: contentRichText ?? undefined,
      images: (images ?? []).map<MediaItemProps>((slide) => {
        const { mobileImage, image, _key: key } = slide ?? {};

        return {
          key: key ?? '',
          image: getImage(image),
          mobileImage: getImage(mobileImage),
        };
      }),
      tabs: (tabs ?? []).map<TabsItemProps>((singleTab) => {
        const { _key: key, heading, content, buttonLink } = singleTab ?? {};
        const { link, buttonLabel } = buttonLink ?? {};

        return {
          key: key ?? '',
          content: content ?? undefined,
          heading: heading ?? '',
          buttonLink:
            {
              link: { to: getHref(link) },
              label: buttonLabel ?? '',
            } ?? {},
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HeroWithTabsSectionFragment on SanityHeroWithTabsSection {
    _key
    _type
    title
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    tabs {
      _key
      heading
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HeroWithTabsSection;
